import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import OnboardingBlock from "../../components/new/onboardingBlock/onboardingBlock"
import Grid from "../../components/new/grid/grid"
import Button from "../../components/button"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import OnboardingCardSm from "../../components/onboardingCardSm"
import { Link } from 'gatsby'

const Shipping = () =>  {
    return (
        <Layout navbar="darkblue">
        <Head title="Returns" description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"/>
            <BackgroundShape style={{shape: "clip-fixed-small", color: "darkblue", particles: true}}/>
                <Container style={{classes: "container" }}>
                <div className="position-relative">
                    <Header content={{title: "Returns", text: "It happens in every business. Returns are inevitable, but not necessarily a nightmare. Processing returns is a smooth process with the right tool."}} style={{color: "text-center"}}/>
                    <Link to='/onboarding' className='btnOn dark-bg' style={{position:'absolute', top: '246px', left:'-14px' }}>← Back</Link>
                </div>
                </Container>
                <Container style={{classes: "container mb-5" }}>
                    <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>Will you handle <span style={{color:'#3b77ce'}}>returns</span> with Onport?</p>
                        <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
                            <div className="text-center col-12 col-md-6">
                                <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0rem auto 2rem auto', padding:'8px 16px', width:'60px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>Yes</p>
                                <p style={{fontWeight:'600', textAlign:'center', fontSize:'18px', marginTop: '8px', marginBottom:'2rem', padding:'0 16px'}}>↓</p>
                                <div className='row'>
                                <div className="col-12 col-md-6">
                                    <OnboardingBlock icon='returns' link='https://jettisupport.zendesk.com/hc/en-us/articles/8085542218908-Returns-Process-in-Onport' title='Manually' description='The Marketplace will raise the returns directly in their Onport account and these will sync to the Vendor Portal to process.'/>
                                    </div>                                
                                <div className="col-12 col-md-6">
                                    <OnboardingBlock icon='automate' link='https://jettisupport.zendesk.com/hc/en-us/articles/7446701566620-Onport-Returns-Portal-Automatic-Returns-within-Onport' title='Automatically' description="The end customer will raise a return via Onport Returns Portal which will sync to the Marketplace's Onport account and sent to the Vendor Portal." />
                                    </div>                                
                                </div>
                            </div>
                            <div className="text-center col-12 col-md-6 w-100">
                                <p style={{background:'#CEE0FB', textTransform:'uppercase', color:'#3b5170', margin: '0rem auto 2rem auto', padding:'8px 16px', width:'60px', fontSize:'.6rem', fontWeight:'bolder', borderRadius:'8px'}}>No</p>
                                <p style={{fontWeight:'600', textAlign:'center', fontSize:'18px', marginTop: '8px', marginBottom:'2rem', padding:'0 16px'}}>↓</p>
                                <p style={{fontWeight:'300', marginTop: '8px', padding:'0 16px'}}>The system remains agnostic to the returns process as these are managed outside of Onport. </p>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container style={{classes: "container mb-5" }}>
                    <div style={{background: '#F4F6F8', padding:'40px 0', margin:'2rem -15px 0 -15px'}} data-sal="slide-up" data-sal-delay='200' data-sal-easing="ease" data-sal-duration="300">
                        <p style={{fontWeight:'700', fontSize: "1rem", marginTop: '8px', textAlign:'center'}}>How will you generate the return <span style={{color:'#3b77ce'}}>labels</span>?</p>
                        <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
                            <div className="text-center col-12 col-md-6 w-100">
                                <OnboardingBlock icon='returns' link='https://help.jetti.io/hc/en-us/articles/7729247330972-Return-Label-Generation#heading-4 ' title='Manually' description='Return labels can be manually generated by the Marketplace from Onport or by the vendor in the Vendor Portal.'/>
                            </div>
                            <div className="text-center col-12 col-md-6 w-100">
                                <OnboardingBlock icon='automate' link='https://help.jetti.io/hc/en-us/articles/7729247330972-Return-Label-Generation#heading-5 ' title='Automatically' description="The return label syncs automatically with each order to the vendor's store or the vendor portal."/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                <OnboardingCardSm content={{
                    slug: 'order', 
                    category: 'ORDERS', 
                    step:'← Previous',
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg", 
                    title: "Orders management", 
                    }}/>
                    <OnboardingCardSm content={{
                    slug: 'payments', 
                    category: 'PAYMENTS',
                    step:'Next →', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg", 
                    title: "Payments", 
                    }}/>
                </div>
                </Container>
        </Layout>
    )
}

export default Shipping